<template>
  <NuxtLink
    to="/read/articles/novosti-mobilnogo-prilozheniya-istoriya-rf"
    class="flex overflow-hidden rounded-xl shadow-drop-0"
  >
    <img
      v-lazy-load
      src="/imgs/banners/left-menu-banner.jpg"
      alt=""
      class="w-full object-cover"
    />
  </NuxtLink>
</template>

<script setup></script>

<style lang="scss"></style>
