<template>
  <div v-if="!isMobile">
    <div
      class="sticky top-24 flex flex-col rounded-[12px] bg-white-100 px-[12px] py-[28px] shadow-drop-0 xl:px-[24px]"
    >
      <div
        class="flex cursor-pointer flex-col font-roboto text-[18px] leading-[120%] text-[#515151]"
      >
        <template v-for="item in navigationItemsNew" :key="item.id">
          <div class="mb-[24px] flex flex-col last:mb-0">
            <Component
              :is="getItemComponent(item)"
              class="flex"
              v-bind="getItemAttrs(item)"
              @click="toggleNestedItems(item)"
            >
              <div class="grid h-5 place-content-center">
                <SvgIcon
                  v-if="item.icon"
                  :name="item.icon"
                  class="size-4 min-w-[16px]"
                />
              </div>
              <span
                class="px-[8px] leading-5"
                :class="{
                  'whitespace-nowrap': !twoLineMenu.includes(item.id),
                }"
                >{{ item.title }}</span
              >
              <SvgIcon
                v-if="item.nested?.length"
                :name="
                  expandedMenuItems.includes(item.id)
                    ? 'chevron-up'
                    : 'chevron-down'
                "
                class="size-4 cursor-pointer"
              />
            </Component>
            <div
              v-if="item.nested && expandedMenuItems.includes(item.id)"
              class="ml-[24px] mt-[12px] flex flex-col gap-[12px] text-[16px]"
            >
              <NuxtLink
                v-for="subItem in item.nested"
                :key="`subItem-${subItem.id}`"
                :to="subItem.href"
                >{{ subItem.title }}</NuxtLink
              >
            </div>
          </div>
        </template>
      </div>
      <div v-if="isDesktop" class="mt-5 flex flex-col font-roboto font-medium">
        <CommonLeftMenuBanner />
      </div>
    </div>
  </div>
</template>

<script setup>
import { navigationItemsNew } from '@/assets/data/navigation';

const NuxtLink = resolveComponent('NuxtLink');
const twoLineMenu = [7, 12, 13, 14, 15];

const { isDesktop, isMobile } = useBreakpoint();
const expandedMenuItems = ref([]);

const { getItemComponent, getItemAttrs, toggleNestedItems } =
  useNavigationMenu(expandedMenuItems);
</script>
