import { NuxtLink } from '#components';

export const useNavigationMenu = (expandedMenuItems) => {
  const getItemComponent = (item) => {
    if (item.external) return 'a';
    return item.nested ? 'div' : NuxtLink;
  };

  const getItemAttrs = (item) => {
    if (item.external) {
      return {
        href: item.href,
        target: '_blank',
      };
    }

    return {
      to: item.href,
    };
  };

  const toggleNestedItems = (item) => {
    if (!item.nested) return;

    if (!item.nested?.length) {
      return;
    }

    if (expandedMenuItems.value.includes(item.id)) {
      expandedMenuItems.value = expandedMenuItems.value.filter(
        (id) => id !== item.id,
      );
    } else {
      expandedMenuItems.value.push(item.id);
    }
  };

  return {
    getItemComponent,
    getItemAttrs,
    toggleNestedItems,
  };
};
